import styled from 'styled-components';

export const CTALinkPrimary = styled.a`
  text-decoration: none;
  padding: 18px 35px;
  border-radius: 100px;
  background-color: #fff;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-family: 'Open Sans', sans-serif;
  color: #2f61f4;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  transition: 0.2s all;
  text-align: center;
  :hover {
    /* background-color: #212c3d;
    color: #fff; */
    img {
      filter: brightness(1000%);
    }
  }
  img {
    width: 16px;
    display: inline-block;
    position: relative;
    padding-right: 0.5rem;
    top: 2px;
  }
`;
