import * as Styled from './CTALinkSecondary.styled';

const CTALinkSecondary = () => {
  return (
    <Styled.Wrapper>
      Interested in finding out more? We are always free for{' '}
      <Styled.CTALinkSecondary href="https://www.virti.com/contact-sales?app=virti-tools-marketing">
        a quick chat!
      </Styled.CTALinkSecondary>
    </Styled.Wrapper>
  );
};

export default CTALinkSecondary;
