import styled from 'styled-components';
export const ButtonLink = styled.a`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: var(--text-font, 'Ubuntu', sans-serif);
  text-decoration: none;
  border: 1px solid transparent;
  background: var(--primary-color, #2f61f4);
  color: var(--primary-color-contrast, #ffffff);
  font-size: 1rem;
  padding: 11px 25px;
  cursor: pointer;
  border-radius: 100px;
  padding: 18px 35px;
  transition: 0.2s all;
  font-family: 'Open Sans', sans-serif;
  :hover {
    background-color: #212c3d;
    color: #fff;
  }
`;
