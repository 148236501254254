import React from 'react';
import Header from './components/Header/Header';
import VideoHandler from './components/VideoHandler/VideoHandler';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-Z34HTKDK11');

function App() {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.send(window.location.pathname + window.location.search);
  }
  return (
    <>
      <Header />
      <VideoHandler />
    </>
  );
}

export default App;
