import * as Styled from './CTALinkPrimary.styled';

const CTALinkPrimary = () => {
  return (
    <Styled.CTALinkPrimary href="https://app.virti.com/register?app=virti-tools-marketing">
      <img
        src="/external.png"
        alt="Square with an arrow pointing up-right, indicating external link."
      />
      Try the Real Thing
    </Styled.CTALinkPrimary>
  );
};

export default CTALinkPrimary;
