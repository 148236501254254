import styled from 'styled-components';

export const Wrapper = styled.p`
  font-size: 1rem;
`;
export const CTALinkSecondary = styled.a`
  margin-top: 1rem;
  color: #fff;
  :hover {
    color: #26bfb1;
  }
`;
