const buttonData = [
  {
    id: 1,
    showAtTime: 0,
    text: "Let's start by editing the greeting dialogue",
    goesToTime: null,
    position: [0.3, 0.3],
  },
  {
    id: 2,
    showAtTime: 7,
    text: "Now it's time to add your first new dialogue!",
    goesToTime: null,
    position: [0.5, 0.35],
  },
  {
    id: 3,
    showAtTime: 11.5,
    text: "Now let's get our Virtual Human to ask the user something!",
    goesToTime: null,
    position: [0.8, 0.5],
  },
  {
    id: 4,
    showAtTime: 19,
    text: 'Of course now we need some choices for the user to say.',
    goesToTime: null,
    position: [0.8, 0.3],
  },
  {
    id: 5,
    showAtTime: 39,
    text: 'Time to finish off with some end nodes!',
    goesToTime: null,
    position: [0.5, 0.5],
  },
  {
    id: 6,
    showAtTime: 54,
    text: 'Why not add some emotion animations too!',
    goesToTime: null,
    position: [0.8, 0.4],
  },
  {
    id: 7,
    showAtTime: 64.6,
    text: "Now let's see it in action!",
    goesToTime: null,
    position: [0.5, 0.5],
  },
];
export default buttonData;

export type btnDataObject = typeof buttonData[0];
