import styled from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  z-index: 10;
  position: absolute;
`;

export const PausedOverlay = styled.div`
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  pointer-events: none;
`;

export const Video = styled.video`
  width: 100%;
`;

export const StartButton = styled.button`
  display: inline-flex;
  position: absolute;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: var(--text-font, 'Ubuntu', sans-serif);
  text-decoration: none;
  border: 1px solid transparent;
  background: var(--primary-color, #2f61f4);
  color: var(--primary-color-contrast, #ffffff);
  font-size: 1rem;
  padding: 11px 25px;
  cursor: pointer;
  border-radius: var(--input-border-radius, 25px);
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const StartAgainButton = styled(StartButton)`
  left: initial;
  top: initial;
  transform: initial;
  position: initial;
`;

export const EndMessage = styled.div`
  font-size: 2rem;
  z-index: 10;
  position: absolute;
  color: #fff;
  line-height: 2.5rem;
  left: 50%;
  top: 50%;
  @media (max-width: 1200px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #000000d1;
  padding: 1rem;
  border-radius: 11px;
  min-width: 300px;
`;

export const CTALinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Or = styled.div`
  padding-bottom: 1rem;
`;
