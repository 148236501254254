import React, { useCallback } from 'react';
import { btnDataObject } from '../buttonData';
import * as Styled from './TourButton.styled';

const TourButton = ({
  children,
  onClick,
  buttonDataObject,
  visible,
}: {
  children: React.ReactNode;
  buttonDataObject: btnDataObject;
  onClick: (id: number, goesToTime: number | null) => void;
  visible: boolean;
}) => {
  const { goesToTime, id, position } = buttonDataObject;
  // const [wasJustClicked, setWasJustClicked] = useState(false);
  const handleClick = useCallback(() => {
    // setWasJustClicked(true);
    console.log('clicked');
    onClick(id, goesToTime);
  }, [goesToTime, id, onClick]);

  // useEffect(() => {
  //   if(videoTime){

  //   }
  // }, [videoTime])

  if (!visible) {
    return <></>;
  }
  return (
    <Styled.TourButton position={position} onClick={handleClick}>
      {children}
    </Styled.TourButton>
  );
};

export default TourButton;
