import styled from 'styled-components';

export const Header = styled.header`
  background-color: var(--midnight);
  color: #fff;
  padding: 5rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 600px;
  font-family: 'Ubuntu';
  gap: 2rem;
  box-shadow: 0 -4px 5px inset #ffffff;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    padding: 2rem;
  }
`;
export const LogoWrapper = styled.div`
  padding: 23px 0 32px 0;
  img {
    width: 150px;
  }
`;
export const H1 = styled.h1`
  margin: 0;
  font-size: 4rem;
  font-weight: 300;
  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;
export const SubText = styled.p`
  font-family: 'Open Sans';
  font-weight: 100;
  font-size: 1.4rem;
`;

export const BillButler = styled.div`
  background-image: url('/billbutler.png');
  width: 100%;
  aspect-ratio: 6 / 4;
  box-shadow: 0 0 7px 1px #101b31 inset;
  border-radius: 14px;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const CTALinks = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  gap: 1rem;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
  @media (max-width: 1000px) {
    flex-direction: row;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
