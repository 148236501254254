import React from 'react';
import * as Styled from './ButtonLink.styled';

const ButtonLink = ({
  children,
  href,
}: {
  children: React.ReactNode;
  href: string;
}) => {
  return <Styled.ButtonLink href={href}>{children}</Styled.ButtonLink>;
};

export default ButtonLink;
