import styled from 'styled-components';

export const TourButton = styled.button<{ position: number[] }>`
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-family: var(--text-font, 'Ubuntu', sans-serif);
  text-decoration: none;
  border: 1px solid transparent;
  background: var(--primary-color, #2f61f4);
  color: var(--primary-color-contrast, #ffffff);
  font-size: 1rem;
  padding: 11px 25px;
  cursor: pointer;
  border-radius: var(--input-border-radius, 25px);
  left: ${(props) => props.position[0] * 100}%;
  top: ${(props) => props.position[1] * 100}%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
`;
