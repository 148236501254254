import { useCallback, useEffect, useRef, useState } from 'react';
import usePrevious from '../../hooks/usePrevious';
import CTALinkPrimary from '../CTALinks/CTALinkPrimary/CTALinkPrimary';
import CTALinkSecondary from '../CTALinks/CTALinkSecondary/CTALinkSecondary';
import buttonData, { btnDataObject } from './buttonData';
import TourButton from './TourButton/TourButton';
import * as Styled from './VideoHandler.styled';

const visibleBtnsMatch = function (
  btns1: btnDataObject[],
  btns2: btnDataObject[]
) {
  if (btns1.length !== btns2.length) return false;

  for (var i = 0; i < btns1.length; i++) {
    if (btns1[i].id !== btns2[i].id) return false;
  }

  return true;
};

const VideoHandler = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [started, setStarted] = useState(false);
  const [paused, setPaused] = useState(true);
  const [finished, setFinished] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  const [shownSoFar, setShownSoFar] = useState<number[]>([]);

  const pause = useCallback(() => {
    if (videoRef.current) {
      setPaused(true);
      videoRef.current.pause();
    }
  }, []);

  const play = useCallback(
    (targetTime?: number) => {
      if (videoRef.current) {
        console.log(targetTime);
        console.log(videoRef.current.currentTime);
        setPaused(false);
        if (targetTime) {
          videoRef.current.currentTime = targetTime;
        }
        videoRef.current.play();
        // we can assume that everything up till this point has been shown
        setShownSoFar(
          buttonData
            .filter((btn) => videoTime >= btn.showAtTime)
            .map((btn) => btn.id)
        );
      }
    },
    [videoTime]
  );

  const handleTourButtonClick = useCallback(
    (id: number, goesToTime: number | null) => {
      if (!goesToTime) {
        play();
      }
    },
    [play]
  );

  const visibleButtons = buttonData.filter(
    (btn) => !shownSoFar.includes(btn.id) && videoTime >= btn.showAtTime
  );

  // console.log(visibleButtons);
  const prevVisibleButtons = usePrevious(visibleButtons);
  const prevVideoTime = usePrevious(videoTime);
  useEffect(() => {
    if (
      visibleButtons &&
      visibleButtons.length > 0 &&
      prevVisibleButtons &&
      !visibleBtnsMatch(visibleButtons, prevVisibleButtons)
    ) {
      pause();
    }
  }, [videoTime, pause, prevVideoTime, visibleButtons, prevVisibleButtons]);

  const startAgain = useCallback(() => {
    setShownSoFar([]);
    setFinished(false);
    setPaused(true);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, []);

  return (
    <section id="branching-video">
      <Styled.VideoWrapper>
        {(!started || paused || finished) && (
          <Styled.PausedOverlay></Styled.PausedOverlay>
        )}
        {!started && (
          <Styled.StartButton
            onClick={() => {
              setStarted(true);
            }}
          >
            See the editor in action!
          </Styled.StartButton>
        )}
        {finished && (
          <>
            <Styled.EndMessage>
              <div>
                Congratulations! You've seen how easy it is to start creating a
                virtual human!
              </div>
              <div>
                <Styled.StartAgainButton onClick={startAgain}>
                  Start again?
                </Styled.StartAgainButton>
              </div>
              <Styled.Or>OR</Styled.Or>
              <Styled.CTALinks>
                <div>
                  <CTALinkPrimary />
                </div>
                <CTALinkSecondary />
              </Styled.CTALinks>
            </Styled.EndMessage>
          </>
        )}
        {started &&
          buttonData.map((btn) => {
            return (
              <TourButton
                key={btn.id}
                onClick={handleTourButtonClick}
                buttonDataObject={btn}
                visible={
                  !shownSoFar.includes(btn.id) && videoTime >= btn.showAtTime
                }
              >
                {btn.text}
              </TourButton>
            );
          })}
      </Styled.VideoWrapper>
      <Styled.Video
        ref={videoRef}
        // controls={true}
        // onClick={(e) => false}
        className="w-full"
        src="https://virti-vh-assets.s3.eu-west-2.amazonaws.com/manual-files/marketing+video+shotcut.mp4"
        onTimeUpdate={(e) => {
          setVideoTime((e.target as HTMLVideoElement).currentTime);
        }}
        onEnded={() => {
          setFinished(true);
        }}
      ></Styled.Video>
      {/* <button onClick={startAgain}>start again</button> */}
    </section>
  );
};

export default VideoHandler;
