import ButtonLink from '../ButtonLink/ButtonLink';
import CTALinkPrimary from '../CTALinks/CTALinkPrimary/CTALinkPrimary';
import CTALinkSecondary from '../CTALinks/CTALinkSecondary/CTALinkSecondary';
import * as Styled from './Header.styled';

const Header = () => {
  return (
    <Styled.Header>
      <div>
        <Styled.LogoWrapper>
          <img
            alt="virti in styled light weight letters"
            src="/virtilogo.svg"
          />
        </Styled.LogoWrapper>
        <Styled.H1 className="">Virtual Human Training</Styled.H1>
        {/* <Styled.H1 className="">Learning Tool Demos</Styled.H1> */}
        <Styled.SubText className="">
          Delivering streamlined tools for the creation of 3D learning
          simulations via Web, Mobile, and VR. Intuitive creation tools for
          in-depth user learning.
        </Styled.SubText>
        <Styled.SubText className="">
          Teach your students soft skills, analyse their responses, and provide
          valuable feedback.
        </Styled.SubText>
        <Styled.CTALinks>
          <ButtonLink href="#branching-video">
            See Our Editor in Action
          </ButtonLink>
          <CTALinkPrimary />
        </Styled.CTALinks>
        <CTALinkSecondary />
      </div>
      <Styled.BillButler />
    </Styled.Header>
  );
};

export default Header;
